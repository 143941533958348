import React, { useEffect, useState } from 'react';
import './Advantages.scss';

import RollingNumber from './RollingNumber/RollingNumber';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';

const Advantages = () => {
  const [countNumber, setCountNumber] = useState();

  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await axios.get(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          `${process.env.REACT_APP_API_URL!}/api/count`,
        );
        if (response.data) {
          setCountNumber(response.data.count);
        }
      } catch (error) {
        console.error('Ошибка при обновлении числа:', error);
      }
    };
    fetchCount();
  }, []);

  return (
    <section className="popular-commodities" ref={ref}>
      <div className="container popular-commodities__container">
        <h2 className="section-title">Our advantages</h2>
        <div className={`advantages ${inView ? 'advantages--visible' : ''}`}>
          <p className="advantages__text">
            Ukrainian products from the agro-industrial complex are renowned
            worldwide for their exceptional quality. Our company possesses
            extensive experience in supplying the Ukrainian agricultural market.
            We collaborate directly with manufacturers from Ukraine. We provide
            the highest quality and most competitive prices for the products we
            supply. We deliver goods that have already undergone customs
            clearance directly to your warehouse.
          </p>
          <p className="advantages__text">
            We do not request advance payments. We are open to working under the
            private label scheme. We offer deferred payment options for our
            regular customers. We prioritize flexibility and tailor our services
            to meet the specific needs of our customers, rather than being
            driven by our own ambitions. By partnering with us, you can expect
            exceptional service regardless of the quantity or size of your
            orders.
          </p>
        </div>
        <div className="advantages__count">
          <h2 className="advantages__title">
            Supplied quantity of tons since 01.01.2025
          </h2>
          <RollingNumber value={countNumber ?? 0} />
        </div>
      </div>
    </section>
  );
};

export default Advantages;
