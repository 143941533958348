import React, { useEffect, useState } from 'react';
import './PopularCommodities.scss';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { PositionType } from 'utils/positionType';

const PopularCommodities = () => {
  const [positions, setPositions] = useState<PositionType | null>(null);

  useEffect(() => {
    fetch('/connodities.json')
      .then((response) => response.json())
      .then((data) => {
        setPositions(data);
      });
  }, []);

  if (!positions) {
    return <LoadingOutlined />;
  }

  return (
    <section className="popular-commodities">
      <div className="container popular-commodities__container">
        <div className="title-wrap">
          <h2 className="section-title">Popular commodities</h2>
          <Link to={'/commodities'} className="title-wrap__link">
            View all <ArrowRightOutlined />
          </Link>
        </div>
        <ul className="popular-commodities__list">
          <li className="popular-commodities__item position">
            <Link
              to={`/commodities/${positions['2'].id}`}
              className="position__link"
            >
              <h3 className="position__title">{positions['2'].title}</h3>
              <div className="position__img-wrap">
                <div className="position__img-container">
                  <img
                    src={positions['2'].image}
                    alt="position"
                    className="position__image"
                  />
                  <img
                    src={positions['2'].sub_image}
                    alt="position"
                    className="position__sub-image"
                  />
                </div>
              </div>

              <button className="position__button">View details</button>
            </Link>
          </li>
          <li className="popular-commodities__item position">
            <Link
              to={`/commodities/${positions['1'].id}`}
              className="position__link"
            >
              <h3 className="position__title">{positions['1'].title}</h3>
              <div className="position__img-wrap">
                <div className="position__img-container">
                  <img
                    src={positions['1'].image}
                    alt="position"
                    className="position__image"
                  />
                  <img
                    src={positions['1'].sub_image}
                    alt="position"
                    className="position__sub-image"
                  />
                </div>
              </div>

              <button className="position__button">View details</button>
            </Link>
          </li>
          <li className="popular-commodities__item position">
            <Link
              to={`/commodities/${positions['3'].id}`}
              className="position__link"
            >
              <h3 className="position__title">{positions['3'].title}</h3>
              <div className="position__img-wrap">
                <div className="position__img-container">
                  <img
                    src={positions['3'].image}
                    alt="position"
                    className="position__image"
                  />
                  <img
                    src={positions['3'].sub_image}
                    alt="position"
                    className="position__sub-image"
                  />
                </div>
              </div>

              <button className="position__button">View details</button>
            </Link>
          </li>
          <li className="popular-commodities__item position">
            <Link
              to={`/commodities/${positions['10'].id}`}
              className="position__link"
            >
              <h3 className="position__title">{positions['10'].title}</h3>
              <div className="position__img-wrap">
                <div className="position__img-container">
                  <img
                    src={positions['10'].image}
                    alt="position"
                    className="position__image"
                  />
                  <img
                    src={positions['10'].sub_image}
                    alt="position"
                    className="position__sub-image"
                  />
                </div>
              </div>

              <button className="position__button">View details</button>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PopularCommodities;
