import { Button, Form, Input } from 'antd';
import axios from 'axios';
import './ContactForm.scss';
import useMessage from 'antd/es/message/useMessage';
import { useState } from 'react';

interface ContactFormValues {
  name: string;
  phone: string;
  company?: string;
  email?: string;
  position?: string;
  website?: string;
}

const ContactForm = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = useMessage();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: ContactFormValues) => {
    try {
      setLoading(true);
      const response = await axios.post(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        `${process.env.REACT_APP_API_URL!}/submit-form`,
        values,
      );
      if (response) {
        setLoading(false);
        return messageApi.success('We will be in touch with you shortly :)');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
    }
  };

  return (
    <section className="contact-form">
      {contextHolder}
      <h2 className="section-title">Contact form</h2>
      <div className="wrap">
        <p className="contact-form__text">
          Please feel free to contact us to get qoutes and responses on all your
          questions.
        </p>
        <div className="contact-form__wrapper">
          <div className="contact-form__form-wrap">
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              className="contact-form__form"
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter your name' }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number',
                  },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <Form.Item
                name="company"
                label="Company"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your company name',
                  },
                ]}
              >
                <Input placeholder="Enter your company name" />
              </Form.Item>
              <Form.Item name="email" label="Email">
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item name="position" label="Position title">
                <Input placeholder="Enter your position title" />
              </Form.Item>
              <Form.Item name="website" label="Website">
                <Input placeholder="Enter your website URL" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        {/* </div> */}
      </div>
    </section>
  );
};

export default ContactForm;
