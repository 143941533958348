import React from 'react';
import gifLogo1 from 'img/gif-logo1.gif';

const Hero = () => {
  return (
    <div className="main__hero hero">
      <picture>
        <source
          srcSet="
  /img/hero/desk1x.jpg 1x,
  /img/hero/desk2x.jpg 2x
"
          type="image/jpeg"
          media="(min-width: 1200px)"
        />
        <source
          srcSet="
  /img/hero/desk1x.jpg 1x,
  /img/hero/desk2x.jpg 2x
"
          type="image/jpeg"
          media="(min-width: 768px)"
        />

        <img src="/img/hero/desk1x.jpg" alt="bg" className="main__img" />
      </picture>

      <div className="main__gif">
        <img src={`${gifLogo1}`} alt="logo" />
      </div>
      <h1 className="main__title">Working hard - Working smart</h1>
    </div>
  );
};

export default Hero;
