import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './NewDetail.scss';
import { ReactComponent as ArrowBack } from 'svg/backarrow.svg';
import axios from 'axios';

const NewsDetail: React.FC = () => {
  const location = useLocation();
  const [newsContent, setNewsContent] = useState<string>('');

  const query = new URLSearchParams(location.search);
  const newsUrl = query.get('url');
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (newsUrl) {
      axios
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .get(`${process.env.REACT_APP_API_URL!}/parse-full-news`, {
          params: { url: newsUrl },
        })
        .then((response) => {
          setNewsContent(response.data.content || 'Новина не знайдена :(');
        })
        .catch((error) => {
          console.error('Помилка:', error);
          setNewsContent('Помилка.');
        });
    }
  }, [newsUrl]);

  return (
    <div className="news-details">
      <div className="container">
        <button
          onClick={() => handleBackClick()}
          className="commoditie-details__back"
        >
          <ArrowBack />
          News
        </button>
        {newsContent ? (
          <div dangerouslySetInnerHTML={{ __html: newsContent }} />
        ) : (
          <p style={{ margin: '100px 0px' }}>Loading...</p>
        )}
        {newsContent && (
          <p>
            Original:{' '}
            <a href={newsUrl ?? ''} rel="noopener noreferrer" target="_blank">
              Link
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default NewsDetail;
