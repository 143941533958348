import React from 'react';
import './Main.scss';
import Hero from './components/Hero/Hero';
import AboutUs from './components/AboutUs/AboutUs';
import PopularCommodities from './components/PopularCommodities/PopularCommodities';
import Advantages from './components/Advantages/Advantages';
import ContactForm from './components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet';

const Main = () => {
  return (
    <>
      <Helmet>
        {/* Название страницы */}
        <title>MGGI - Main page</title>
        {/* Мета-теги для SEO */}
        <meta
          name="description"
          content="Miami Global Grain Industries LLC is a wholesaler of agricultural products of Ukrainian origin in the US.
Exceptional-quality agricultural commodities directly from Ukrainian manufacturers to your warehouse!
Barley malt, livestock and horse feed, wheat flour, wheat bran, vegetable oils, wheat gluten."
        />
        <meta
          name="keywords"
          content="MGGI, popular products, leading supplier, contact form, learn about us, grain delivery, grain"
        />
        <meta name="author" content="Miami Global Grain Industries LLC" />

        {/* Open Graph теги для социальных сетей */}
        <meta property="og:title" content="MGGI - Main page" />
        <meta
          property="og:description"
          content="MGGI is a wholesaler of agricultural products of Ukrainian origin in the US.
Exceptional-quality agricultural commodities directly from Ukrainian manufacturers to your warehouse!
Barley malt, livestock and horse feed, wheat flour, wheat bran, vegetable oils, wheat gluten."
        />

        <meta property="og:type" content="website" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      <section className="main">
        <h1 className="title visually-hidden">
          Miami Global Grain Industries LLC
        </h1>
        <h2 className="title visually-hidden">
          MGGI is a wholesaler of agricultural products of Ukrainian origin in
          the US. Exceptional-quality agricultural commodities directly from
          Ukrainian manufacturers to your warehouse! Barley malt, livestock and
          horse feed, wheat flour, wheat bran, vegetable oils, wheat gluten.
        </h2>
        <Hero />
        <AboutUs />
        <PopularCommodities />
        <Advantages />
        <ContactForm />
      </section>
    </>
  );
};

export default Main;
