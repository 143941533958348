import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Position } from 'utils/positionType';
import { ReactComponent as ArrowBack } from 'svg/backarrow.svg';
import './CommoditieDetails.scss';
import { Helmet } from 'react-helmet';

const CommoditieDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [position, setPosition] = useState<Position | null>(null);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!id) return;
    fetch('/connodities.json')
      .then((response) => response.json())
      .then((data) => {
        setPosition(data[id]);
      });
  }, [id]);

  if (!position) {
    return <div style={{ textAlign: 'center' }}>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        {/* Page title */}
        <title>MGGI - {position.title} Details</title>
        {/* Meta tags for SEO */}
        <meta
          name="description"
          content={`Explore detailed information about ${position.title}. Learn about its specifications, origin, vitamins, minerals, packaging, and more from Miami Global Grain Industries LLC.`}
        />
        <meta
          name="keywords"
          content={`MGGI, ${position.title}, agricultural commodity, specifications, origin, vitamins, minerals, packaging`}
        />
        <meta name="author" content="Miami Global Grain Industries LLC" />
        {/* Open Graph tags for social media */}
        <meta
          property="og:title"
          content={`MGGI - ${position.title} Details`}
        />
        <meta
          property="og:description"
          content={`Learn about ${position.title}, including its key specifications, origin, vitamins, minerals, and more, provided by Miami Global Grain Industries LLC.`}
        />

        <meta property="og:type" content="website" />
      </Helmet>
      <section className="commoditie-details">
        <div className="container commoditie-details__container">
          <button
            onClick={() => handleBackClick()}
            className="commoditie-details__back"
          >
            <ArrowBack />
            Commodities
          </button>
          <div className="details">
            <div className="details__img">
              <img src={position.image} alt="logo" />
            </div>
            <div className="details__list">
              <h2 className="details__title">{position.title}</h2>
              <div className="details__item">
                <h3 className="details__subtitle">Description</h3>
                <p>{position.description}</p>
              </div>
              <div className="details__item">
                <h3 className="details__subtitle">Key specifications</h3>
                <ul className="details__spec">
                  {Object.entries(position.specifications).map(
                    ([key, value]) => {
                      return (
                        <li key={key} className="details__info">
                          <span className="details__label">
                            {key.replace(/_/g, ' ')}:
                          </span>
                          <span className="details__value">{value}</span>
                        </li>
                      );
                    },
                  )}
                </ul>
              </div>
              {position.vitamins && (
                <div className="details__item">
                  <h3 className="details__subtitle">Vitamins</h3>
                  <p>{position.vitamins}</p>
                </div>
              )}
              {position.minerals && (
                <div className="details__item">
                  <h3 className="details__subtitle">
                    Minerals and microelements
                  </h3>
                  <p>{position.minerals}</p>
                </div>
              )}
              <div className="details__item">
                <h3 className="details__subtitle">Origin</h3>
                <p>{position.origin}</p>
              </div>
              <div className="details__item">
                <h3 className="details__subtitle">Documents</h3>
                <p>{position.documents}</p>
              </div>
              <div className="details__item">
                <h3 className="details__subtitle">Additional information</h3>
                <p>{position.additional_information}</p>
              </div>
              {position.packaging && (
                <div className="details__item">
                  <h3 className="details__subtitle">Packaging</h3>
                  <p>{position.packaging}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommoditieDetails;
