import React, { useContext } from 'react';
import './News.scss';
import { Link } from 'react-router-dom';
import NewsContext from 'NewsContext/NewsContext';
import bgLogo from 'img/bg-news.jpg';

const NewsSection: React.FC = () => {
  const { state } = useContext(NewsContext);
  return (
    <section className="news commodities">
      <div className="bg commodities__bg">
        <img src={bgLogo} alt="bg" />
      </div>
      <div className="container news__container">
        <div className="news-cards">
          {state.news.map((item, index) => (
            <div key={index} className="news-card">
              <h3>{item.title}</h3>
              <Link
                to={`/news/details?url=${encodeURIComponent(item.descriptionLink)}`}
					 className="news-card__link"
              >
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
