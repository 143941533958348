import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Link, NavLink } from 'react-router-dom';
import logo from 'img/logo.png';

const Header = () => {
  const [isNavigationOpened, setNavigationOpened] = useState(false);
  const navigationClass = isNavigationOpened ? 'navigation--opened' : '';

  useEffect(() => {
    if (isNavigationOpened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isNavigationOpened]);

  // Функция для закрытия меню и разблокировки скролла
  const handleMenuItemClick = () => {
    if (window.innerWidth < 767) {
      setNavigationOpened(false);
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__menu menu">
          <div className="menu__logo">
            <Link to="/">
              <img src={logo} alt="logo" className="menu__img" />
            </Link>
          </div>
          <nav className={`navigation ${navigationClass}`}>
            <ul className="menu__list">
              <li className="menu__item">
                <NavLink
                  to="/"
                  className="menu__link"
                  onClick={handleMenuItemClick}
                >
                  Home
                </NavLink>
              </li>
              <li className="menu__item">
                <NavLink
                  to="/commodities"
                  className="menu__link"
                  onClick={handleMenuItemClick}
                >
                  Commodities
                </NavLink>
              </li>
              <li className="menu__item">
                <NavLink
                  to="/news"
                  className="menu__link"
                  onClick={handleMenuItemClick}
                >
                  News
                </NavLink>
              </li>
              <li className="menu__item">
                <NavLink
                  to="/contacts"
                  className="menu__link"
                  onClick={handleMenuItemClick}
                >
                  Contacts
                </NavLink>
              </li>
            </ul>
          </nav>

          <div
            className={`hamburger ${isNavigationOpened ? 'active' : ''}`}
            onClick={() => setNavigationOpened((prev) => !prev)}
          >
            <span className="hamburger__line"></span>
            <span className="hamburger__line"></span>
            <span className="hamburger__line"></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
