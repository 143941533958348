import React from 'react';
import './Footer.scss';
import logo from 'img/logo.png';
import { Link } from 'react-router-dom';
import { ReactComponent as Geo } from 'svg/geo.svg';
import { ReactComponent as Mail } from 'svg/mail.svg';
import { ReactComponent as Phone } from 'svg/tel.svg';
const Footer = () => {
  const phone = '+15614400896';
  const mail = 'mggillc@gmail.com';
  const address = '19255, Bay Leaf Ct, Boca Raton, FL, 33498, USA';
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__top">
          <div className="menu__logo">
            <Link to="/">
              <img src={logo} alt="logo" className="menu__img" />
            </Link>
          </div>

          <a href={`tel:${phone}`} className="footer__tel footer__link">
            <Phone />
            {phone}
          </a>
          <a href={`mailto:${mail}`} className="footer__mail footer__link">
            <Mail />
            {mail}
          </a>
          <a
            href={`https://www.google.com/maps?q=${address}`}
            target="_blank"
            rel="noopener noreferrer"
            className="footer__address footer__link"
          >
            <Geo />
            {address}
          </a>
        </div>
        <div className="footer__copyright">
          ©2024 by Miami Global Grain Industries LLC. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
