import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './pages/Layout/Layout';
import Main from 'pages/Main/Main';
import Contacts from 'pages/Contacts/Contacts';
import Commodities from 'pages/Commodities/Commodities';
import CommoditieDetails from 'pages/CommoditieDetails/CommoditieDetails';
import NewsSection from 'pages/News/News';
import NewsDetail from 'pages/NewsDetail/NewsDetail';
import { NewsProvider } from 'NewsContext/NewsContext';

const App = () => {
  return (
    <NewsProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Main />} />
          <Route path="commodities" element={<Commodities />} />
          <Route path="commodities/:id" element={<CommoditieDetails />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="news" element={<NewsSection />} />
          <Route path="news/details" element={<NewsDetail />} />
        </Route>
        <Route path="*" element={<Navigate to={`/`} replace />} />
      </Routes>
    </NewsProvider>
  );
};

export default App;
