import React from 'react';
import bglogo from 'img/contacts.jpg';
import './Contacts.scss';
import { ReactComponent as Phone } from 'svg/contacts/phone.svg';
import { ReactComponent as Email } from 'svg/contacts/email.svg';
import { ReactComponent as Hours } from 'svg/contacts/hours.svg';
import { ReactComponent as Geo } from 'svg/contacts/geo.svg';
import { ReactComponent as Web } from 'svg/contacts/web.svg';
import ContactForm from 'pages/Main/components/ContactForm/ContactForm';
import { Helmet } from 'react-helmet';

const Contacts = () => {
  const phone = '+15614400896';
  const mail = 'mggillc@gmail.com';
  const address = '19255, Bay Leaf Ct, Boca Raton, FL, 33498, USA';

  return (
    <>
      <Helmet>
        {/* Page title */}
        <title>MGGI - Contact Us</title>
        {/* Meta tags for SEO */}
        <meta
          name="description"
          content="Contact Miami Global Grain Industries LLC. Find our phone number, email address, location, and business hours."
        />
        <meta
          name="keywords"
          content="MGGI, contact information, phone number, email, address, business hours"
        />
        <meta name="author" content="Miami Global Grain Industries LLC" />
        {/* Open Graph tags for social media */}
        <meta property="og:title" content="MGGI - Contact Us" />
        <meta
          property="og:description"
          content="Get in touch with Miami Global Grain Industries LLC. Find our contact details including phone number, email, address, and business hours."
        />

        <meta property="og:type" content="website" />
        {/* Meta tags for responsive design */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <section className="contacts">
        <div className="bg">
          <img src={bglogo} alt="bg" />
        </div>
        <div className="container">
          <div className="contacts__title">
            <h2 className="section-title">Contacts</h2>
            <h3 className="contacts__sub-title">
              Miami Global Grain Industries LLC
            </h3>
          </div>

          <p className="contacts__text">
            We&apos;re here to help and answer any questions you might have. We
            look forward to hearing from you.
          </p>

          <div className="contacts__data">
            <ul className="contacts__list">
              <li className="contacts__item">
                <Phone className="contacts__icon" />
                <h3 className="contacts__name">Phone</h3>
                <a href={`tel:${phone}`} className="contacts__link">
                  {phone}
                </a>
              </li>
              <li className="contacts__item">
                <Email className="contacts__icon" />
                <h3 className="contacts__name">Email</h3>
                <a href={`mailto:${mail}`} className="contacts__link">
                  {mail}
                </a>
              </li>
              <li className="contacts__item">
                <Web className="contacts__icon" />
                <h3 className="contacts__name">WEBSITE</h3>
                <a href={`https://mggi.us`} className="contacts__link">
                  https://mggi.us
                </a>
              </li>

              <li className="contacts__item">
                <Geo className="contacts__icon" />
                <h3 className="contacts__name">HEADQUATER</h3>
                <a
                  href={`https://www.google.com/maps?q=${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contacts__link"
                >
                  {address}
                </a>
              </li>
              <li className="contacts__item">
                <Hours className="contacts__icon" />
                <h3 className="contacts__name">OPENING HOURS</h3>
                <p className="contacts__link">
                  9:00 AM to 5:00 PM EDT Monday-Friday
                </p>
              </li>
            </ul>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
};

export default Contacts;
