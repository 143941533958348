import React from 'react';
import './AboutUs.scss';
import { useWindowSize } from 'react-use';

const AboutUs = () => {
  const { width } = useWindowSize();

  return (
    <section className="main__about-us about-us">
      <div className="container about-us__container">
        <h2 className="section-title">About us</h2>
        <div className="about-us__grid">
          {width > 1000 && (
            <p className="about-us__text">
              <span>
                Our organization is a trusted importer and supplier in the
                agricultural industry.
              </span>

              <span>
                Our mission is to provide high-quality products to feed people
                and livestock globally.
              </span>

              <span>
                We uphold the principles of honesty, integrity, transparency,
                and readiness.
              </span>

              <span>
                Our objective is to offer the most competitive prices and
                exceptional quality commodities to our valued customers.
              </span>
            </p>
          )}
        </div>
        {width < 1000 && (
          <p className="about-us__text">
            <span>
              Our organization is a trusted importer and supplier in the
              agricultural industry.
            </span>

            <span>
              Our mission is to provide high-quality products to feed people and
              livestock globally.
            </span>

            <span>
              We uphold the principles of honesty, integrity, transparency, and
              readiness.
            </span>

            <span>
              Our objective is to offer the most competitive prices and
              exceptional quality commodities to our valued customers.
            </span>
          </p>
        )}
      </div>
    </section>
  );
};

export default AboutUs;
