import React, { useEffect, useState } from 'react';
import './RollingNumber.scss';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface RollingNumberProps {
  value: number;
}

const RollingNumber: React.FC<RollingNumberProps> = ({ value }) => {
  const [formattedValue, setFormattedValue] = useState<(string | 'dot')[]>([]);
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    const stringValue = value.toString();
    const parts: (string | 'dot')[] = [];

    for (let i = 0; i < stringValue.length; i++) {
      if ((stringValue.length - i) % 3 === 0 && i !== 0) {
        parts.push('dot');
      }
      parts.push(stringValue[i]);
    }

    setFormattedValue(parts);
  }, [value]);

  return (
    <div ref={ref} className="animated-number">
      {formattedValue.map((part, index) =>
        part === 'dot' ? (
          <div key={index} className="dot"></div>
        ) : (
          <div key={index} className="animated-digit">
            <motion.div
              className="digit-container"
              initial={{ y: '-720px', x: '-50%' }}
              animate={
                inView ? { y: `-${parseInt(part) * 72}px` } : { y: '-720px' }
              }
              transition={{
                duration: 0.8,
                ease: [0.16, 1, 0.3, 1],
              }}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <div key={i}>{i}</div>
              ))}
            </motion.div>
          </div>
        ),
      )}
    </div>
  );
};

export default RollingNumber;
