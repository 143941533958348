import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import gifLogo1 from 'img/gif-logo1.gif';
import logo from 'img/logo.png';
import './Layout.scss';

const Layout = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = gifLogo1;
    img.onload = () => {
      setTimeout(() => {
        setIsLoaded(true);
      }, 500);
    };
  }, []);

  return (
    <div className="layout">
      {!isLoaded ? (
        <div className="global">
          <img src={logo} alt="logo" />
          <h1 className="main__title">Working hard - Working smart</h1>
          <p className="global__placeholder">
            The padge is loading, please wait, if you couldn&apos;t get it, find
            a better internet connection!
          </p>
        </div>
      ) : (
        <>
          <Header />
          <div className="app__main">
            <Outlet />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
