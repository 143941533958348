import React, { useEffect, useState } from 'react';
import './Commodities.scss';
import bgLogo from 'img/bg-commodities.jpg';
import { PositionType } from 'utils/positionType';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Commodities = () => {
  const [positions, setPositions] = useState<PositionType | null>(null);

  useEffect(() => {
    fetch('/connodities.json')
      .then((response) => response.json())
      .then((data) => {
        setPositions(data);
      });
  }, []);

  if (!positions) {
    return <LoadingOutlined />;
  }

  return (
    <>
      <Helmet>
        {/* Page title */}
        <title>MGGI - Commodities</title>
        {/* Meta tags for SEO */}
        <meta
          name="description"
          content="Explore a wide range of commodities offered by Miami Global Grain Industries LLC. Find the best agricultural products sourced from Ukraine."
        />
        <meta
          name="keywords"
          content="MGGI, commodities, agricultural products, Ukraine, grain, trade, export"
        />
        <meta name="author" content="Miami Global Grain Industries LLC" />
        {/* Open Graph tags for social media */}
        <meta property="og:title" content="MGGI - Commodities" />
        <meta
          property="og:description"
          content="Discover a variety of commodities offered by Miami Global Grain Industries LLC. Contact us to learn more about our agricultural products from Ukraine."
        />

        {/* Replace with the URL of your commodities page */}
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="commodities">
        <div className="bg commodities__bg">
          <img src={bgLogo} alt="bg" />
        </div>
        <div className="container commodities__container">
          <div className="commodities__title">
            <h2 className="section-title">Commodities</h2>
            <ul className="popular-commodities__list">
              {Object.values(positions).map((position) => (
                <li
                  key={position.id}
                  className="popular-commodities__item position"
                >
                  <Link
                    to={`/commodities/${position.id}`}
                    className="position__link"
                  >
                    <h3 className="position__title">{position.title}</h3>
                    <div className="position__img-wrap">
                      <div className="position__img-container">
                        <img
                          src={position.image}
                          alt={position.title}
                          className="position__image"
                        />
                        <img
                          src={position.sub_image}
                          alt={position.title}
                          className="position__sub-image"
                        />
                      </div>
                    </div>

                    <button className="position__button">View details</button>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="commodities__contact">
              <div className="commodities__contact-title">
                Haven’t you found what you are looking for?
              </div>
              <div className="commodities__contact-text">
                You can contact us to request any agricultural commodities from
                Ukraine.
              </div>
              <Link to={'/contacts'} className="commodities__contact-btn">
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commodities;
